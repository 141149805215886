// defaults.js
'use strict';
import React from 'react';

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
export const NULL_FUNCTION = () => null;
export const EMPTY_ELEMENT = <div />;

export default {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  NULL_FUNCTION,
};
