// createElementWrapper.js
'use client';
import React from 'react';

import { getIsInBrowserMainThread } from './getJsEnvironment.js';
import createTrackingEvent from './createTrackingEvent.js';

const isBrowserMainThread = getIsInBrowserMainThread();

const originalCreateElement = React.createElement;

function createReactElement() {
  const props = arguments[1] || {};

  const isClickTrackingDisabled =
    !!arguments[1]?.['data-click_tracking_disabled'];
  if (isClickTrackingDisabled) {
    return originalCreateElement.apply(React, arguments);
  }

  const isAnchorElement =
    (arguments[0] === 'a' ||
      // There's something different after Next.js' version is greater than 14.0.4.
      // Somehow Next.js doesn't create "a" element for LinkWithLanguage anymore while it used to.
      // (The only element we can intercept is `Symbol(react.forward_ref)`)
      arguments[0]?.displayName === 'LinkWithLanguage') &&
    !props.onClick;
  if (
    (typeof props.onClick === 'function' &&
      /**
       * The difference between `onClick.isIntercepted` and `iscomponenthandled`:
       *
       * - onClick.isIntercepted:
       *   Prevent `onClick` property from being intercepted multiple times.
       *   (e.g.)(styled-components v5) styled.div`...`
       *
       * - iscomponenthandled:
       *   Avoid unnecessary wrapper for the same `onClick` property.
       *   (e.g.)(react-router-dom v5) LinkAnchor.
       *   Refer to the following link for details:
       *   https://github.com/remix-run/react-router/blob/v5.2.0/packages/react-router-dom/modules/Link.js#L37
       */
      !props.onClick.isIntercepted &&
      !props.iscomponenthandled) ||
    isAnchorElement
  ) {
    const originalOnClick = props.onClick;
    props.onClick = function (...onClickArguments) {
      const event = onClickArguments[0];
      if (!event || !event?.target || event.isAlreadyCalled) {
        // Check originalOnClick before invoking it since it might meet `isAnchorElement` but without `onClick` specified.
        // e.g., https://github.com/swaglive/swag-webapp/blob/v3.180.1/src/client/js/page/Settings.jsx#L296
        return originalOnClick?.(...onClickArguments);
      }

      // if there is a `onClick()` callback function in onClick(), two click events are sent.
      // we have to exclude the second one.
      // ex. https://github.com/swaglive/swag-webapp/blob/3f50e85c75c3381caf2ea70cc9063b8abebd22bd/src/client/js/component/Accordion.jsx#L77
      event.isAlreadyCalled = true;
      event.target.isTrackedByMonkeyPatch = true;

      let payload;
      if (props?.['data-element_id']) {
        payload = {
          buttonId: props['data-element_id'],
        };
      }
      const dataTrackingPayload = props?.['data-tracking_payload'];
      if (typeof dataTrackingPayload === 'string') {
        try {
          const parsedTrackingPayload = JSON.parse(dataTrackingPayload);
          payload = {
            ...payload,
            ...parsedTrackingPayload,
          };
        } catch (e) {
          // Not a valid JSON string
          window.__ERROR_RECOVERY_WORDING__ = `Invalid payload: ${e}`;
          window.__showErrorRecoveryToast__();
        }
      } else if (dataTrackingPayload) {
        payload = {
          ...payload,
          ...dataTrackingPayload,
        };
      }

      window.dispatchEvent(
        new CustomEvent('globalclick', {
          detail: {
            originalEvent: event,
            ...payload,
            ...createTrackingEvent(event.currentTarget),
            srcTarget: 'component',
          },
        })
      );
      originalOnClick && originalOnClick(...onClickArguments);
    };
    props.onClick.isIntercepted = true;
    props.iscomponenthandled = 1;
    props.suppressHydrationWarning = true;
  }

  return originalCreateElement.apply(React, arguments);
}

if (isBrowserMainThread) {
  React.createElement = createReactElement;
}
